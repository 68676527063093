<template>
  <RevCard class="flex flex-col">
    <div class="flex space-x-16 p-24">
      <component :is="icon" v-if="hasIcon" />

      <div class="flex flex-1 flex-col">
        <div class="body-1-bold order-2 mb-8">
          <slot name="title" />
        </div>

        <div v-if="$slots.kicker" class="body-2 order-1 mb-8">
          <slot name="kicker" />
        </div>

        <div v-if="$slots.description" class="body-2 order-4 mb-16 md:mb-0">
          <slot name="description" />
        </div>

        <div v-if="$slots.tags" class="order-3 mb-8 space-x-8">
          <slot name="tags" />
        </div>

        <div class="order-5 flex items-center justify-between">
          <div v-if="$slots.price" class="body-2-bold">
            <slot name="price" />
          </div>

          <RevButton
            class="ml-auto block md:hidden"
            :disabled
            :loading
            size="small"
            variant="primary"
            @click="handleSelect"
          >
            {{ i18n(translations.select) }}
          </RevButton>
        </div>
      </div>

      <div class="hidden self-end md:block">
        <RevButton
          :disabled
          :loading
          size="small"
          variant="primary"
          @click="handleSelect"
        >
          {{ i18n(translations.select) }}
        </RevButton>
      </div>
    </div>
  </RevCard>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'

import translations from './FunnelChoice.translations'
import type { FunnelChoiceProps } from './FunnelChoice.types'

const emit = defineEmits<{
  select: [value: string]
}>()

const props = defineProps<FunnelChoiceProps>()

const i18n = useI18n()

const hasIcon = computed(() => !isEmpty(props.icon))

function handleSelect() {
  emit('select', props.value)
}
</script>
