import { createHttpEndpoint } from '../utils'

import type { OrderlineDetailsResponse } from './types/orderlineDetailsResponse'
import type { OrderlineListResponse } from './types/orderlineListResponse'
import type { OrderlineTimelineResponse } from './types/orderlineTimelineResponse'

export type getOrderlineListParams = {
  page?: number
  pageSize?: number
}

export const getOrderlineList = createHttpEndpoint<OrderlineListResponse>({
  defaultQueryParams: { pageSize: '6' },
  method: 'GET',
  operationId: 'orderline_list',
  path: '/my-orders/orderlines',
})

export const getOrderlineDetails = createHttpEndpoint<OrderlineDetailsResponse>(
  {
    method: 'GET',
    operationId: 'orderline_detailed',
    path: '/my-orders/orderlines/:orderlineId',
  },
)

export const getOrderlineTimeline =
  createHttpEndpoint<OrderlineTimelineResponse>({
    method: 'GET',
    operationId: 'orderline_timeline',
    path: '/my-orders/orderlines/:orderlineId/timeline',
  })
