<template>
  <FunnelChoice :icon :loading :value="optionType" @select="handleSelect">
    <template #title>
      <slot name="title" />
    </template>

    <template #kicker>
      <slot name="kicker" />
    </template>

    <template #description>
      <slot name="description" />
    </template>

    <template #tags v-if="$slots.tags">
      <slot name="tags" />
    </template>

    <template #price v-if="$slots.price">
      <slot name="price" />
    </template>
  </FunnelChoice>
</template>

<script lang="ts" setup>
import FunnelChoice from '@/components/FunnelChoice/FunnelChoice.vue'

import type { ResolutionOptionCardShellProps } from './ResolutionOptionCardShell.types'

const emit = defineEmits<{
  select: [value: string]
}>()

defineProps<ResolutionOptionCardShellProps>()

function handleSelect(event: string) {
  emit('select', event)
}
</script>
